import { graphql, useStaticQuery } from 'gatsby';

export const useHelmetQuery = () => {
  const { contentfulAbout } = useStaticQuery(graphql`
    query ContentfulAboutQuery {
      contentfulAbout {
        name
        description
        preview {
          resize(width: 1200) {
            src
          }
        }
        profile {
          favicon16: resize(width: 16) {
            src
          }
          favicon32: resize(width: 32) {
            src
          }
          bigIcon: resize(width: 192) {
            src
          }
          appleIcon: resize(width: 180) {
            src
          }
        }
      }
    }
  `);

  return contentfulAbout;
};
